export default [
  {
    path: 'teams',
    name: 'teams',
    meta: {
      requiresPermissions: ['ASSIGN_ROLE'],
    },
    component: () =>
      import(/* webpackChunkName: "teams" */ '@/views/Home/Teams'),
  },
  {
    path: 'teams/create',
    name: 'teams.create',
    meta: {
      requiresPermissions: ['ASSIGN_ROLE'],
    },
    component: () =>
      import(
        /* webpackChunkName: "user.create" */ '@/views/Home/Teams/CreateTeam'
      ),
  },
  {
    path: 'teams/:id',
    name: 'teams.details',
    meta: {
      requiresPermissions: ['ASSIGN_ROLE'],
    },
    component: () =>
      import(
        /* webpackChunkName: "user.details" */ '@/views/Home/Teams/TeamDetails'
      ),
  },
]
