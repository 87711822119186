import Attendee from '@/models/Attendee'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    attendeeDetails: null,
  },

  mutations: {
    setAttendeeList(state, attendees) {
      each(attendees, (attendee) => {
        const exist = find(state.list, { id: attendee.id })
        if (!exist) {
          state.list.push(new Attendee(attendee))
        }
      })
    },

    clearAttendeeList(state) {
      state.list = []
    },

    setAttendeeListMeta(state, meta) {
      state.listMeta = meta
    },

    setAttendeeDetails(state, attendee) {
      if (attendee instanceof Attendee) {
        state.attendeeDetails = attendee
      } else {
        state.attendeeDetails = new Attendee(attendee)
      }
    },

    clearAttendeeDetails(state) {
      state.attendeeDetails = null
    },
  },

  actions: {
    async getAttendees({ commit, rootGetters }, params) {
      const query = Attendee.custom(`events/${params.event_id}/attendees`)
        .page(params.page || 1)
        .limit(params.limit || 30)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      const res = await query.get()
      commit('setAttendeeList', res.data)
      commit('setAttendeeListMeta', res.meta)
    },

    getAttendeeByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },
  },
}
