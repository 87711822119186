import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Profile extends Model {
  resource() {
    return 'profiles'
  }

  get buildingName() {
    const maxLength = 35

    if (!this.buildings || this.buildings.length === 0) return 'NA'

    const names = this.buildings.map((item) => item.name)
    let result = names.join(', ')

    if (result.length > maxLength) {
      result = result.substring(0, maxLength) + '...'
    }

    return result
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get ownerName() {
    if (!this.owner) return 'NA'
    return this.owner.full_name
  }
}
