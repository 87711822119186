import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(utc)

function convertToDayjs(date) {
  return date ? dayjs.utc(date).local() : false
}

function dateFormat(date, format = DATETIME_FORMAT.format) {
  return date ? convertToDayjs(date).format(format) : ''
}

function dateOnlyFormat(date, format = DATETIME_FORMAT.dateFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function eventDateOnlyFormat(date, format = DATETIME_FORMAT.eventDateFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function timeOnlyFormat(date, format = DATETIME_FORMAT.timeFormat) {
  return date ? convertToDayjs(date).format(format) : ''
}

function dateForHuman(date) {
  return date ? convertToDayjs(date).from() : ''
}

function isSameDate(dateA, dateB) {
  return convertToDayjs(dateA).isSame(convertToDayjs(dateB), 'day')
}

export {
  convertToDayjs,
  dateFormat,
  dateOnlyFormat,
  timeOnlyFormat,
  dateForHuman,
  eventDateOnlyFormat,
  isSameDate,
}
