import { find, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    loading: true,
    buildingId: null,
    buildingList: [],
    loadingBuilding: false,
  },

  getters: {
    buildingStorage() {
      return 'filterBuilding'
    },

    buildingId(state) {
      return state.buildingId
    },
  },

  mutations: {
    setBuildingId(state, payload) {
      state.buildingId =
        isNaN(payload) || payload <= 0 ? null : parseInt(payload)
    },

    setBuildingList(state, payload) {
      state.buildingList = payload
    },

    setLoading(state, payload) {
      state.loading = payload
    },

    addBuilding(state, payload) {
      const exist = find(state.buildingList, { id: payload.id })
      if (!exist) {
        state.buildingList.push(payload)
      }
    },

    removeBuilding(state, payload) {
      const index = findIndex(state.buildingList, { id: payload.id })
      if (index >= 0) {
        state.buildingList.splice(index)
      }
    },

    updateBuilding(state, payload) {
      const index = findIndex(state.buildingList, { id: payload.id })
      if (index >= 0) {
        state.buildingList[index] = payload
      }
    },

    setLoadingBuilding(state, payload) {
      state.loadingBuilding = payload
    },
  },

  actions: {
    //
  },
}
