import Model from './Model'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { dateFormat, eventDateOnlyFormat, timeOnlyFormat } from '@/utils/date'

export default class Event extends Model {
  resource() {
    return 'events'
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get categoryLabel() {
    if (!this.category) return 'NA'
    return this.category.label
  }

  get eventDate() {
    if (!this.datetime_start || !this.datetime_end) return 'NA'
    return `${eventDateOnlyFormat(
      this.datetime_start,
      DATETIME_FORMAT.shortDateFormat
    )} ${timeOnlyFormat(this.datetime_start)} - ${timeOnlyFormat(
      this.datetime_end
    )}`
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }
}
