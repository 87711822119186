import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Building extends Model {
  resource() {
    return 'properties'
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }
}
