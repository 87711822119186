import LobbyTv from '@/models/LobbyTv'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    lobbyTvDetails: null,
    totalLobbyTvs: 0,
  },

  mutations: {
    setLobbyTvList(state, tvs) {
      state.list = tvs
      state.totalLobbyTvs = 0
    },

    clearLobbyTvList(state) {
      state.list = []
    },

    setLobbyTvListMeta(state, meta) {
      state.listMeta = meta
    },

    setLobbyTvDetails(state, lobbyTv) {
      if (state.lobbyTvDetails && !lobbyTv.logo) {
        lobbyTv.logo = state.lobbyTvDetails.logo
      }

      if (state.lobbyTvDetails && !lobbyTv.background_image) {
        lobbyTv.background_image = state.lobbyTvDetails.background_image
      }

      if (state.lobbyTvDetails && !lobbyTv.placeholder_gallery_image) {
        lobbyTv.placeholder_gallery_image =
          state.lobbyTvDetails.placeholder_gallery_image
      }

      if (lobbyTv instanceof LobbyTv) {
        state.lobbyTvDetails = lobbyTv
      } else {
        state.lobbyTvDetails = new LobbyTv(lobbyTv)
      }
    },

    setLobbyTvLogo(state, payload) {
      if (state.lobbyTvDetails) {
        state.buildingDetails.logo = payload.image
      }
    },

    clearLobbyTvDetails(state) {
      state.lobbyTvDetails = null
    },

    setTotalLobbyTvs(state, total) {
      state.totalLobbyTvs = total
    },
  },

  actions: {
    async getLobbyTvs({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = LobbyTv.page(params.page || 1).limit(
          params.itemsPerPage || 10
        )

        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }
        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        const filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['building'])

        res = await query.get()
        commit('setLobbyTvList', res.data)
        commit('setLobbyTvListMeta', res.meta)
        commit('setTotalLobbyTvs', get(res, 'meta.total', 0))
      } else {
        res = await LobbyTv.params({ paginate: false }).get()
        return res.data
      }
    },

    getLobbyTvByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getLobbyTvDetails({ commit }, id) {
      const { data } = await Api.get(`televisions/${id}`)
      commit('setLobbyTvDetails', data.data)
    },

    async createLobbyTv({ commit }, formData) {
      const { data } = await Api.post(`televisions`, formData)
      commit('setLobbyTvDetails', data.data)
    },

    async updateLobbyTv({ commit }, formData) {
      const { data } = await Api.put(`televisions/${formData.id}`, formData)
      commit('setLobbyTvDetails', data.data)
    },

    async updateLobbyTvImage({ commit }, formData) {
      const id = formData.get('id')
      const { data } = await Api.post(`televisions/${id}?_method=PUT`, formData)
      commit('setLobbyTvDetails', data.data)
    },

    async deleteLobbyTvImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)
      commit('setLobbyTvLogo', {
        image: null,
      })
    },
  },
}
