import Model from './Model'
import { EXTERNAL_BOOKING } from '@/utils/enums/BookingOption'
import { dateFormat } from '@/utils/date'
import { capitalize } from '@/utils/helpers'

export default class Bookable extends Model {
  resource() {
    return 'bookables'
  }

  get images() {
    return this.media || []
  }

  get typeLabel() {
    return capitalize(this.type)
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get bookingOption() {
    return capitalize(this.booking_option)
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get isExternalBooking() {
    return this.booking_option === EXTERNAL_BOOKING
  }
}
