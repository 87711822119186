import Vue from 'vue'

const host = window.location.host
// const parts = host.split('.')
const protocol = window.location.protocol
let tenant = null

if (host.includes('brookland')) {
  tenant = 'brookland'
} else if (host.includes('esperance')) {
  tenant = 'esperance'
} else if (host.includes('samfordgrove')) {
  tenant = 'samfordgrove'
} else if (host.includes('fairway')) {
  tenant = 'fairway'
} else if (host.includes('canvasliving')) {
  tenant = 'canvasliving'
} else if (host.includes('providence')) {
  tenant = 'providence'
} else if (host.includes('serenitypines')) {
  tenant = 'serenitypines'
} else if (host.includes('liven')) {
  tenant = 'liven'
} else if (host.includes('majestic')) {
  tenant = 'majestic'
} else if (host.includes('maleny')) {
  tenant = 'maleny'
} else {
  tenant = ''
}

let url =
  `${protocol}//${tenant}` +
  '.' +
  process.env.VUE_APP_API_CENTRAL_DOMAIN +
  '/api/v1'

if (tenant === 'canvasliving') {
  url = `${protocol}//` + process.env.VUE_APP_API_CANVASLIVING + '/api/v1'
}

var domainName = 'Canvas Living'
var firebaseAppId = ''
var firebaseProjectId = ''
var firebaseApiKey = ''
var firebaseMessagingSenderId = ''

switch (tenant) {
  case 'esperance':
    domainName = 'Esperance'
    firebaseAppId = process.env.VUE_APP_FIREBASE_ESPERANCE_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_ESPERANC_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_ESPERANCE_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_ESPERANCE_SENDER_ID
    break
  case 'brookland':
    domainName = 'Brookland'
    firebaseAppId = process.env.VUE_APP_FIREBASE_BROOKLAND_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_BROOKLAND_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_BROOKLAND_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_BROOKLAND_SENDER_ID
    break
  case 'samfordgrove':
    domainName = 'Samford Grove'
    firebaseAppId = process.env.VUE_APP_FIREBASE_SAMFORD_GROVE_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_SAMFORD_GROVE_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_SAMFORD_GROVE_API_KEY
    firebaseMessagingSenderId =
      process.env.VUE_APP_FIREBASE_SAMFORD_GROVE_SENDER_ID
    break
  case 'fairway':
    domainName = 'Fairway'
    firebaseAppId = process.env.VUE_APP_FIREBASE_FAIRWAY_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_FAIRWAY_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_FAIRWAY_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_FAIRWAY_SENDER_ID
    break
  case 'providence':
    domainName = 'Providence'
    firebaseAppId = process.env.VUE_APP_FIREBASE_PROVIDENCE_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_PROVIDENCE_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_PROVIDENCE_API_KEY
    firebaseMessagingSenderId =
      process.env.VUE_APP_FIREBASE_PROVIDENCE_SENDER_ID
    break
  case 'serenitypines':
    domainName = 'Serenity Pines'
    firebaseAppId = process.env.VUE_APP_FIREBASE_SERENITYPINES_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_SERENITYPINES_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_SERENITYPINES_API_KEY
    firebaseMessagingSenderId =
      process.env.VUE_APP_FIREBASE_SERENITYPINES_SENDER_ID
    break
  case 'liven':
    domainName = 'Liven'
    firebaseAppId = process.env.VUE_APP_FIREBASE_LIVEN_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_LIVEN_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_LIVEN_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_LIVEN_SENDER_ID
    break
  case 'majestic':
    domainName = 'Majestic Lifestyle'
    firebaseAppId = process.env.VUE_APP_FIREBASE_MAJESTIC_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_MAJESTIC_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_MAJESTIC_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_MAJESTIC_SENDER_ID
    break
  case 'maleny':
    domainName = 'Maleny Grove'
    firebaseAppId = process.env.VUE_APP_FIREBASE_MALENY_APP_ID
    firebaseProjectId = process.env.VUE_APP_FIREBASE_MALENY_PROJECT_ID
    firebaseApiKey = process.env.VUE_APP_FIREBASE_MALENY_API_KEY
    firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_MALENY_SENDER_ID
    break
}

const env = {
  appName: domainName,
  apiUrl: url,
  domain: tenant,
  firebaseAppId,
  firebaseProjectId,
  firebaseApiKey,
  firebaseMessagingSenderId,
}

Vue.prototype.$env = env

export default env
