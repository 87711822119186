export default [
  {
    path: 'users',
    name: 'users',
    meta: {
      requiresPermissions: ['VIEW_USERS'],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
  },
  {
    path: 'users/create',
    name: 'user.create',
    meta: {
      requiresPermissions: ['CREATE_USERS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "user.create" */ '@/views/Home/Users/CreateUser'
      ),
  },
  {
    path: 'users/:id',
    name: 'user.details',
    meta: {
      requiresPermissions: ['VIEW_USERS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "user.details" */ '@/views/Home/Users/UserDetails'
      ),
  },
]
