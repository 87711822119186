import User from './User'
import Avatar from './Avatar'
import { dateFormat } from '@/utils/date'
import { sentence } from '@/utils/helpers'

export default class Team extends User {
  resource() {
    return 'teams'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  get avatarText() {
    return this.first_name.charAt(0) + this.last_name.charAt(0)
  }

  get statusLabel() {
    if (this.deleted_at) return 'Deleted'
    return !this.blocked_at ? 'Active' : 'Blocked'
  }

  get blockedOrRemoved() {
    return !!this.blocked_at || !!this.deleted_at
  }

  get blockedOrRemovedLabel() {
    let condition = []

    if (this.blocked_at) condition.push('blocked')
    if (this.deleted_at) condition.push('archived')

    return sentence(condition)
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get onboardedDate() {
    return dateFormat(this.onboarded_at)
  }

  get isOnboarded() {
    return !!this.onboarded_at
  }

  get isUpdatable() {
    return !!(!this.blocked_at && !this.deleted_at)
  }

  get flatRoleName() {
    if (this.roles && Array.isArray(this.roles)) {
      return this.roles.join(', ')
    }

    return ''
  }
}
