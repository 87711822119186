const REQUEST_BOOKING = 'request'
const EXTERNAL_BOOKING = 'external'
const NO_BOOKING = 'none'

const BOOKING_OPTION = [
  {
    text: 'Request Booking',
    value: REQUEST_BOOKING,
  },
  {
    text: 'External Booking',
    value: EXTERNAL_BOOKING,
  },
  {
    text: 'No Booking',
    value: NO_BOOKING,
  },
]

export { REQUEST_BOOKING, EXTERNAL_BOOKING, NO_BOOKING, BOOKING_OPTION }
