import Maintenance from '@/models/Maintenance'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    maintenanceDetails: null,
    totalMaintenances: 0,
    maintenanceStatusNews: [],
    totalMaintenanceStatusNews: 0,
    maintenanceStatusInprogress: [],
    totalMaintenanceStatusInprogress: 0,
  },

  mutations: {
    setMaintenanceList(state, maintenances) {
      state.list = maintenances
    },

    clearMaintenanceList(state) {
      state.list = []
      state.totalMaintenances = 0
      state.maintenanceStatusNews = []
      state.maintenanceStatusInprogress = []
      state.totalMaintenanceStatusNews = 0
      state.totalMaintenanceStatusInprogress = 0
    },

    setMaintenanceListMeta(state, meta) {
      state.listMeta = meta
    },

    setMaintenanceDetails(state, maintenance) {
      if (state.maintenanceDetails) {
        if (!maintenance.media || !maintenance.media.length)
          maintenance.media = state.maintenanceDetails.media
        if (!maintenance.user) maintenance.user = state.maintenanceDetails.user
        if (!maintenance.building)
          maintenance.building = state.maintenanceDetails.building
      }

      if (maintenance instanceof Maintenance) {
        state.maintenanceDetails = maintenance
      } else {
        state.maintenanceDetails = new Maintenance(maintenance)
      }
    },

    clearMaintenanceDetails(state) {
      state.maintenanceDetails = null
    },

    setTotalMaintenaces(state, total) {
      state.totalMaintenances = total
    },

    setMaintenanceNews(state, maintenances) {
      state.maintenanceStatusNews = maintenances
    },

    setTotalMaintenacesNews(state, total) {
      state.totalMaintenanceStatusNews = total
    },

    setMaintenanceInprogress(state, maintenances) {
      state.maintenanceStatusInprogress = maintenances
    },

    setTotalMaintenacesInprogress(state, total) {
      state.totalMaintenanceStatusInprogress = total
    },
  },

  actions: {
    async getMaintenances({ commit, rootGetters }, params) {
      const query = Maintenance.page(params.page || 1).orderBy(params.sort)

      if (params.filter) {
        if (params.filter === 'deleted') {
          query.where('trashed', 'only')
        } else {
          query.where('status', params.filter)
        }
      } else {
        query.where('trashed', 'with')
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include([
        'user',
        'building',
        'category',
        'urgency',
        'property',
        'user.property',
      ])

      const res = await query.params({ limit: params.itemsPerPage || 30 }).get()

      commit('setMaintenanceList', res.data)
      commit('setMaintenanceListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalMaintenaces', get(res, 'meta.total', 0))
      }
    },

    async getMaintenanceDetails({ commit }, id) {
      const { data } = await Api.get(
        `maintenances/${id}?include=media,user,building,user.property`
      )
      commit('setMaintenanceDetails', data.data)
    },

    async updateMaintenance({ commit }, formData) {
      const { data } = await Api.put(`maintenances/${formData.id}`, formData)
      commit('setMaintenanceDetails', data.data)
    },

    async getMaintenanceStatusNews({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || '',
        include: [
          'user',
          'building',
          'media',
          'category',
          'urgency',
          'property',
          'user.property',
        ],
        limit: params.itemsPerPage || 5,
      })

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `maintenances/new?${queryParams.toString()}`
      const { data } = await Api.get(url)
      commit('setMaintenanceNews', data.data)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalMaintenacesNews', get(data, 'meta.total', 0))
      }
    },

    async getMaintenanceStatusInProgress({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || '',
        include: [
          'user',
          'building',
          'media',
          'category',
          'urgency',
          'property',
          'user.property',
        ],
        limit: params.itemsPerPage || 5,
      })

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `maintenances/in_progress?${queryParams.toString()}`

      const { data } = await Api.get(url)

      commit('setMaintenanceInprogress', data.data)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalMaintenacesInprogress', get(data, 'meta.total', 0))
      }
    },
  },
}
