export default [
  {
    path: 'notifications',
    name: 'notifications',
    meta: {
      requiresPermissions: ['VIEW_NOTIFICATIONS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '@/views/Home/Notifications'
      ),
  },
  {
    path: 'notifications/create',
    name: 'notification.create',
    meta: {
      requiresPermissions: ['CREATE_NOTIFICATIONS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "notification.create" */ '@/views/Home/Notifications/CreateNotification'
      ),
  },
  {
    path: 'notifications/:id',
    name: 'notification.details',
    meta: {
      requiresPermissions: ['VIEW_NOTIFICATIONS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "notification.details" */ '@/views/Home/Notifications/NotificationDetails'
      ),
  },
]
