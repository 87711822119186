import Model from './Model'
import { truncate } from '@/utils/helpers'
import { dateFormat } from '@/utils/date'

export default class Notification extends Model {
  resource() {
    return 'notifs'
  }

  get shortNotification() {
    return truncate(this.notification, 50)
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get expiryDate() {
    return dateFormat(this.expires_at)
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }
}
