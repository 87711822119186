export default [
  {
    path: 'news',
    name: 'news',
    meta: {
      requiresPermissions: ['VIEW_NEWS'],
    },
    component: () => import(/* webpackChunkName: "news" */ '@/views/Home/News'),
  },
  {
    path: 'news/create',
    name: 'news.create',
    meta: {
      requiresPermissions: ['CREATE_NEWS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "news.create" */ '@/views/Home/News/CreateNews'
      ),
  },
  {
    path: 'news/:id',
    name: 'news.details',
    meta: {
      requiresPermissions: ['VIEW_NEWS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "news.details" */ '@/views/Home/News/NewsDetails'
      ),
  },
]
