function truncate(str, n = 75) {
  return str.length > n ? str.substr(0, n - 1) + '..' : str
}

function capitalize(str) {
  return str ? str.toString().charAt(0).toUpperCase() + str.slice(1) : ''
}

function sentence(arr) {
  if (Array.isArray(arr)) {
    if (arr.length <= 2) {
      return arr.join(' and ')
    }

    const last = arr.pop()
    return arr.join(', ') + ' and ' + last
  }

  return arr
}

function isValidLink(link) {
  /* eslint-disable-next-line no-useless-escape */
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(link)
}

function isValidEmail(email) {
  const reg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return email ? reg.test(String(email).toLowerCase()) : false
}

export { capitalize, truncate, sentence, isValidEmail, isValidLink }
