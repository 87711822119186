import Model from './Model'
import Avatar from './Avatar'
import { dateFormat } from '@/utils/date'
import { callableFormat } from '@/utils/phone'

export default class Contact extends Model {
  resource() {
    return 'contacts'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  get avatarText() {
    return this.first_name.charAt(0) + this.last_name.charAt(0)
  }

  get phoneNumber() {
    return callableFormat(this.phone_number)
  }

  get telephoneNumber() {
    return this.telephone_number
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }
}
