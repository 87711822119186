export default [
  {
    path: 'perks',
    name: 'perks',
    meta: {
      requiresPermissions: ['VIEW_PERKS'],
    },
    component: () =>
      import(/* webpackChunkName: "perks" */ '@/views/Home/Perks'),
  },
  {
    path: 'perks/create',
    name: 'perk.create',
    meta: {
      requiresPermissions: ['CREATE_PERKS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "perk.create" */ '@/views/Home/Perks/CreatePerk'
      ),
  },
  {
    path: 'perks/:id',
    name: 'perk.details',
    meta: {
      requiresPermissions: ['VIEW_PERKS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "perk.details" */ '@/views/Home/Perks/PerkDetails'
      ),
  },
]
