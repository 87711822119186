import GoogleLibPhoneNumber from 'google-libphonenumber'

const PHONE_UTIL = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance()
const PNF = GoogleLibPhoneNumber.PhoneNumberFormat
const PNT = GoogleLibPhoneNumber.PhoneNumberType
const AU_REGION = 'AU'
const PH_REGION = 'PH'

function callableFormat(phone) {
  if (!phone) return

  const regExp = /^0[0-9].*$/
  return regExp.test(phone) ? phone : `+${phone}`
}

function isValidMobileNumber(value) {
  // Remove spaces from the input
  const cleanedValue = value.replace(/\s/g, '')

  if (!cleanedValue || !/^\d+$/.test(cleanedValue)) return false

  const auNumber = PHONE_UTIL.parseAndKeepRawInput(cleanedValue, AU_REGION)
  const phNumber = PHONE_UTIL.parseAndKeepRawInput(cleanedValue, PH_REGION)

  return !!(
    (PHONE_UTIL.isValidNumberForRegion(auNumber, AU_REGION) ||
      PHONE_UTIL.isValidNumberForRegion(phNumber, PH_REGION)) &&
    (isInNationalFormat(auNumber) || isInNationalFormat(phNumber)) &&
    (isNumberType(auNumber, PNT.MOBILE) || isNumberType(phNumber, PNT.MOBILE))
  )
}

function isValidTelephoneNumber(value) {
  // Remove spaces from the input
  const cleanedValue = value.replace(/\s/g, '')
  if (!cleanedValue || !/^\d+$/.test(cleanedValue)) return false

  const auNumber = PHONE_UTIL.parseAndKeepRawInput(value, AU_REGION)
  const phNumber = PHONE_UTIL.parseAndKeepRawInput(value, PH_REGION)

  return !!(
    (PHONE_UTIL.isValidNumberForRegion(auNumber, AU_REGION) ||
      PHONE_UTIL.isValidNumberForRegion(phNumber, PH_REGION)) &&
    (isNumberType(auNumber, PNT.FIXED_LINE) ||
      isNumberType(phNumber, PNT.FIXED_LINE) ||
      isNumberType(auNumber, PNT.TOLL_FREE) ||
      isNumberType(phNumber, PNT.TOLL_FREE) ||
      isNumberType(auNumber, PNT.SHARED_COST) ||
      isNumberType(phNumber, PNT.SHARED_COST) ||
      isNumberType(auNumber, PNT.FIXED_LINE_OR_MOBILE) ||
      isNumberType(phNumber, PNT.FIXED_LINE_OR_MOBILE))
  )
}

function isInNationalFormat(number) {
  const nationalFormat = PHONE_UTIL.format(number, PNF.NATIONAL).replace(
    /[^A-Z0-9]/gi,
    ''
  )

  return nationalFormat === number.getRawInput()
}

function isNumberType(number, type) {
  return PHONE_UTIL.getNumberType(number) === type
}

export { callableFormat, isValidMobileNumber, isValidTelephoneNumber }
