import Profile from '@/models/Profile'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    profileDetails: null,
    totalProfiles: 0,
  },

  mutations: {
    setProfileList(state, profiles) {
      state.list = profiles
    },

    clearProfileList(state) {
      state.list = []
    },

    setProfileDetails(state, profile) {
      if (profile instanceof Profile) {
        state.profileDetails = profile
      } else {
        state.profileDetails = new Profile(profile)
      }
    },

    setTotalProfiles(state, total) {
      state.totalProfiles = total
    },

    clearProfileDetails(state) {
      state.profileDetails = null
    },
  },

  actions: {
    async getProfiles({ commit, rootGetters }, params) {
      let res = null
      if (params) {
        const query = Profile.page(params.page || 1).orderBy(params.sort)

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        query.include(['buildings', 'owner'])

        res = await query.params({ limit: params.itemsPerPage || 10 }).get()

        commit('setProfileList', res.data)

        if (get(res, 'meta.total', 0) > 0) {
          commit('setTotalProfiles', get(res, 'meta.total', 0))
        }
      } else {
        res = await Profile.params({ paginate: false, is_active: true }).get()
        return res.data
      }
    },

    async getProfilesByBuildingId({ commit, rootGetters }, buildingId) {
      const { data } = await Api.get(`profiles/${buildingId}/list`)
      return data.data
    },

    async getProfileDetails({ commit }, id) {
      const { data } = await Api.get(`profiles/${id}`)
      commit('setProfileDetails', data.data)
    },

    async createProfile({ commit }, formData) {
      const { data } = await Api.post(`profiles`, formData)
      commit('setProfileDetails', data.data)
    },

    async updateProfile({ commit }, formData) {
      const { data } = await Api.put(`profiles/${formData.id}`, formData)
      commit('setProfileDetails', data.data)
    },

    async deleteProfile({ commit }, id) {
      await Api.delete(`profiles/${id}`)
    },
  },
}
