export default [
  {
    path: 'gallery',
    name: 'gallery',
    meta: {
      requiresPermissions: ['VIEW_GALLERIES'],
    },
    component: () =>
      import(/* webpackChunkName: "gallery" */ '@/views/Home/Gallery'),
  },
  {
    path: 'gallery/create',
    name: 'gallery.create',
    meta: {
      requiresPermissions: ['CREATE_GALLERIES'],
    },
    component: () =>
      import(
        /* webpackChunkName: "gallery.create" */ '@/views/Home/Gallery/CreateGallery'
      ),
  },
  {
    path: 'gallery/:id',
    name: 'gallery.details',
    meta: {
      requiresPermissions: ['VIEW_GALLERIES'],
    },
    component: () =>
      import(
        /* webpackChunkName: "gallery.details" */ '@/views/Home/Gallery/GalleryDetails'
      ),
  },
]
