import PERMISSION from '../utils/enums/Permission'

export default [
  {
    path: 'feature-profile',
    name: 'feature-profile',
    meta: {
      requiresPermissions: [
        PERMISSION.FEATURE_PROFILE_VIEW,
        PERMISSION.FEATURE_PROFILE_CREATE,
        PERMISSION.FEATURE_PROFILE_UPDATE,
        PERMISSION.FEATURE_PROFILE_DELETE,
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/FeatureProfile'
      ),
  },
  {
    path: 'feature-profile/create',
    name: 'feature-profile.create',
    meta: {
      requiresPermissions: [
        PERMISSION.FEATURE_PROFILE_VIEW,
        PERMISSION.FEATURE_PROFILE_CREATE,
        PERMISSION.FEATURE_PROFILE_UPDATE,
        PERMISSION.FEATURE_PROFILE_DELETE,
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/FeatureProfile/CreateProfile'
      ),
  },
  {
    path: 'feature-profile/:id',
    name: 'feature-profile.details',
    meta: {
      requiresPermissions: [
        PERMISSION.FEATURE_PROFILE_VIEW,
        PERMISSION.FEATURE_PROFILE_CREATE,
        PERMISSION.FEATURE_PROFILE_UPDATE,
        PERMISSION.FEATURE_PROFILE_DELETE,
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "building.details" */ '@/views/Home/FeatureProfile/ProfileDetails'
      ),
  },
]
