export default [
  {
    path: 'buildings',
    name: 'buildings',
    meta: {
      requiresPermissions: ['VIEW_BUILDINGS'],
    },
    component: () =>
      import(/* webpackChunkName: "buildings" */ '@/views/Home/Buildings'),
  },
  {
    path: 'buildings/create',
    name: 'building.create',
    meta: {
      requiresPermissions: ['CREATE_BUILDINGS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "building.create" */ '@/views/Home/Buildings/CreateBuilding'
      ),
  },
  {
    path: 'buildings/:id',
    name: 'building.details',
    meta: {
      requiresPermissions: ['VIEW_BUILDINGS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "building.details" */ '@/views/Home/Buildings/BuildingDetails'
      ),
  },
]
