export default [
  {
    path: 'home',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/Home'
      ),
  },
]
