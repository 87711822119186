import Team from '@/models/Team'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 1,
      last_page: 1,
      total: 0,
    },
    teamDetails: null,
  },

  mutations: {
    setTeamList(state, teams) {
      each(teams, (user) => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new Team(user))
        }
      })
    },

    clearTeamList(state) {
      state.list = []
    },

    setTeamListMeta(state, meta) {
      state.listMeta = meta
    },

    setTeamDetails(state, user) {
      if (state.teamDetails && !user.avatar) {
        user.avatar = state.teamDetails.avatar
      }

      if (user instanceof Team) {
        state.teamDetails = user
      } else {
        state.teamDetails = new Team(user)
      }
    },

    setSelectedTeamAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.teamDetails.avatar = avatar
      } else {
        state.teamDetails.avatar = new Avatar(avatar)
      }
    },

    clearTeamDetails(state) {
      state.teamDetails = null
    },

    teamAvatarRemoved(state) {
      state.teamDetails.avatar = null
    },
  },

  actions: {
    async getTeams({ commit }, params) {
      const query = Team.page(params.page || 1)
        .include([
          // 'avatar',
          'buildings',
          // 'property'
        ])
        .orderBy(params.sort)

      switch (params.filter) {
        case 'active':
          break
        case 'deleted':
          query.where('trashed', 'only')
          break
        case 'blocked':
          query.where('only_blocked', 1)
          break
        case 'all':
        default:
          query.where('trashed', 'with').where('with_blocked', 1)
          break
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      const res = await query.params({ limit: params.limit || 30 }).get()

      commit('setTeamList', res.data)
      commit('setTeamListMeta', res.meta)
    },

    async getTeamDetails({ commit }, id) {
      const { data } = await Api.get(`teams/${id}`)
      commit('setTeamDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `users/${formData.get('id')}/avatar`,
        formData
      )
      commit('setSelectedTeamAvatar', data.data)
    },

    async registerTeam({ commit }, formData) {
      const { data } = await Api.post(`teams`, formData)
      commit('setTeamDetails', data.data)
    },

    async updateTeam({ commit }, formData) {
      const { data } = await Api.put(`teams/${formData.id}`, formData)
      commit('setTeamDetails', data.data)
    },

    async blockTeam({ commit }, { id, action }) {
      const { data } = await Api.post(`admin/teams/${id}/${action}`)
      commit('setTeamDetails', data.data)
    },

    async restoreTeam({ commit }, formData) {
      const { data } = await Api.post(`teams/${formData.id}/restore`)
      commit('setTeamDetails', data.data)
    },

    async exportToExcel({ commit }, params) {
      const file = await Api.get(
        `/teams/export${params && params.sort ? `?sort=${params.sort}` : ''}`,
        {
          responseType: 'blob',
        }
      )
      return file
    },
  },
}
