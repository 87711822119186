import Model from './Model'
import { dateFormat } from '@/utils/date'
import { callableFormat } from '@/utils/phone'

export default class Event extends Model {
  resource() {
    return 'events'
  }

  get phoneNumber() {
    return callableFormat(this.phone_number)
  }

  get noOfGuests() {
    const guests = this.event_confirmation ? this.event_confirmation.plus : null
    return guests > 0 ? `+${guests}` : `Just me`
  }

  get joinDate() {
    return this.event_confirmation
      ? dateFormat(this.event_confirmation.created_at)
      : null
  }
}
