import { isEmpty, intersection } from 'lodash'

const validatePermissions = (requiresPermissions, userPermissions) => {
  if (
    requiresPermissions &&
    Array.isArray(requiresPermissions) &&
    userPermissions &&
    userPermissions.length > 0
  ) {
    // Check if the user has the required permission
    const isAllow = !isEmpty(intersection(userPermissions, requiresPermissions))
    if (!isAllow) {
      console.log('permission: ', [...requiresPermissions])
      console.log('not allow permission')

      return false
    }
  }

  return true
}

export { validatePermissions }
