import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Faq extends Model {
  resource() {
    return 'faqs'
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }
}
