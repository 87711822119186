const dateFormat = 'MMM DD, YYYY'
const shortDateFormat = 'DD/MM/YYYY'
const timeFormat = 'hh:mm A'
const eventDateFormat = 'ddd DD/MM/YYYY'

const DATETIME_FORMAT = {
  dateFormat: dateFormat,
  shortDateFormat: shortDateFormat,
  timeFormat: timeFormat,
  eventDateFormat: eventDateFormat,
  format: `${dateFormat} ${timeFormat}`,
}

export default DATETIME_FORMAT
