<template>
  <div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      appName: 'appName',
      domainName: 'domainName',
    }),
  },
  created() {
    this.setFavicons()
  },
  methods: {
    setFavicons() {
      document.title = this.appName
      document.getElementById('favicon').href =
        '/favicon/' + this.domainName + '/favicon.ico'

      document.getElementById('favicon16x16').href =
        '/favicon/' + this.domainName + '/favicon-16x16.png'

      document.getElementById('favicon32x32').href =
        '/favicon/' + this.domainName + '/favicon-32x32.png'

      document.getElementById('favicon192x192').href =
        '/favicon/' + this.domainName + '/android-192x192.png'

      document.getElementById('favicon-apple').href =
        '/favicon/' + this.domainName + '/apple-touch-icon.ico'
    },
  },
}
</script>
