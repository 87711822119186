import Contact from '@/models/Contact'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    contactDetails: null,
    totalContacts: 0,
  },

  mutations: {
    setContactList(state, contacts) {
      state.list = contacts
    },

    clearContactList(state) {
      state.list = []
      state.totalContacts = 0
    },

    setContactListMeta(state, meta) {
      state.listMeta = meta
    },

    setContactDetails(state, contact) {
      if (state.contactDetails) {
        if (!contact.avatar) contact.avatar = state.contactDetails.avatar
      }

      if (contact instanceof Contact) {
        state.contactDetails = contact
      } else {
        state.contactDetails = new Contact(contact)
      }
    },

    setSelectedContactAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.contactDetails.avatar = avatar
      } else {
        state.contactDetails.avatar = new Avatar(avatar)
      }
    },

    clearContactDetails(state) {
      state.contactDetails = null
    },

    contactAvatarRemoved(state) {
      state.contactDetails.avatar = null
    },

    setTotalContact(state, total) {
      state.totalContacts = total
    },
  },

  actions: {
    async getContacts({ commit, rootGetters }, params) {
      const query = Contact.page(params.page || 1)
        .include(['avatar', 'building'])
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      const res = await query.params({ limit: params.itemsPerPage || 30 }).get()

      commit('setContactList', res.data)
      commit('setContactListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalContact', get(res, 'meta.total', 0))
      }
    },

    async getContactDetails({ commit }, id) {
      const { data } = await Api.get(`contacts/${id}?include=avatar`)
      commit('setContactDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `contacts/${formData.get('id')}/avatar`,
        formData
      )
      commit('setSelectedContactAvatar', {
        ...data.data,
        thumb_url: formData.get('avatar_url'),
      })
    },

    async registerContact({ commit }, formData) {
      const { data } = await Api.post(`contacts`, formData)
      commit('setContactDetails', data.data)
    },

    async updateContact({ commit }, formData) {
      const { data } = await Api.put(`contacts/${formData.id}`, formData)
      commit('setContactDetails', data.data)
    },
  },
}
