import HomePage from '@/models/HomePage'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    homePageDetails: null,
  },

  mutations: {
    setHomePageDetails(state, homePage) {
      if (homePage instanceof HomePage) {
        state.homePageDetails = homePage
      } else {
        state.homePageDetails = new HomePage(homePage)
      }
    },

    clearHomePageDetails(state) {
      state.homePageDetails = null
    },
  },

  actions: {
    async getHomePageDetails({ commit }, id) {
      const { data } = await Api.get(`home-settings`)
      commit('setHomePageDetails', data.data)
    },

    async updateHomePage({ commit }, formData) {
      const { data } = await Api.post(`home-settings`, formData)
      commit('setHomePageDetails', data.data)
    },

    async uploadHomePageBanner({ commit }, formData) {
      const { data } = await Api.post(`home-settings`, formData)
      commit('setHomePageDetails', data.data)
    },
  },
}
