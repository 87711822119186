export default [
  {
    path: 'services-and-spaces',
    name: 'bookables',
    meta: {
      requiresPermissions: ['VIEW_BOOKABLES'],
    },
    component: () =>
      import(/* webpackChunkName: "bookables" */ '@/views/Home/Bookables'),
  },
  {
    path: 'services-and-spaces/create',
    name: 'bookable.create',
    meta: {
      requiresPermissions: ['CREATE_BOOKABLES'],
    },
    component: () =>
      import(
        /* webpackChunkName: "bookable.create" */ '@/views/Home/Bookables/CreateBookable'
      ),
  },
  {
    path: 'services-and-spaces/:id',
    name: 'bookable.details',
    meta: {
      requiresPermissions: ['VIEW_BOOKABLES'],
    },
    component: () =>
      import(
        /* webpackChunkName: "bookable.details" */ '@/views/Home/Bookables/BookableDetails'
      ),
  },
]
