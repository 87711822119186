import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Perk extends Model {
  resource() {
    return 'perks'
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get isRatingUp() {
    if (this.likes_count === this.dislikes_count) return null
    return this.likes_count > this.dislikes_count
  }
}
