import Property from '@/models/Property'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    propertyDetails: null,
  },

  mutations: {
    setPropertyList(state, properties) {
      each(properties, (property) => {
        const exist = find(state.list, { id: property.id })
        if (!exist) {
          state.list.push(new Property(property))
        }
      })
    },

    clearPropertyList(state) {
      state.list = []
    },

    setPropertyListMeta(state, meta) {
      state.listMeta = meta
    },

    clearPropertyDetails(state) {
      state.propertyDetails = null
    },
  },

  actions: {
    async getProperties({ commit }, params) {
      let res = null

      if (params) {
        const query = Property.page(params.page || 1).limit(params.limit || 30)

        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        res = await query.get()
        commit('setPropertyList', res.data)
        commit('setPropertyListMeta', res.meta)
      } else {
        res = await Property.params({ paginate: false }).get()
        return res.data
      }
    },

    async getPropertiesByBuilding({ state }, buildingId) {
      const { data } = await Api.get(`properties/${buildingId}/list`)
      return data.data
    },
  },
}
