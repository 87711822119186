export default {
  namespaced: true,
  state: {
    message: '',
    list: '',
    color: '',
  },

  mutations: {
    setMessage(state, message) {
      state.message = message
    },

    setList(state, list) {
      state.list = list
    },

    setColor(state, color) {
      state.color = color
    },
  },

  actions: {
    show({ commit }, data) {
      commit('setMessage', data.message)
      commit('setList', data.list)
      commit('setColor', data.color)
    },
  },
}
