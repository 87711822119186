export default [
  {
    path: '/event',
    name: 'event',
    redirect: { name: 'event.dashboard' },
    component: () =>
      import(/* webpackChunkName: "event" */ '@/views/Home/Event'),
    children: [
      {
        path: 'dashboard',
        name: 'event.dashboard',
        meta: {
          requiresPermissions: ['VIEW_EVENTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "event.dashboard" */ '@/views/Home/Event/Events'
          ),
      },
      {
        path: 'lists/create',
        name: 'event.list.create',
        meta: {
          requiresPermissions: ['CREATE_EVENTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "event.list.create" */ '@/views/Home/Event/Events/CreateEvent'
          ),
      },
      {
        path: 'lists/:id',
        name: 'event.list.details',
        meta: {
          requiresPermissions: ['VIEW_EVENTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "event.list.details" */ '@/views/Home/Event/Events/EventDetails'
          ),
      },
      {
        path: 'categories',
        name: 'event.categories',
        component: () =>
          import(
            /* webpackChunkName: "event.categories" */ '@/views/Home/Event/Categories'
          ),
      },
      {
        path: 'categories/create',
        name: 'event.category.create',
        component: () =>
          import(
            /* webpackChunkName: "event.category.create" */ '@/views/Home/Event/Categories/CreateCategory'
          ),
      },
      {
        path: 'categories/:id',
        name: 'event.category.details',
        component: () =>
          import(
            /* webpackChunkName: "event.category.details" */ '@/views/Home/Event/Categories/CategoryDetails'
          ),
      },
    ],
  },
]
