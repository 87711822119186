// import Comment from '@/models/Comment'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    newsList: [],
  },

  mutations: {
    setNewsCommentList(state, comments) {
      state.newsList = comments
    },
  },

  actions: {
    async getNewsComments({ commit }, params) {
      let res = null
      if (params) {
        res = await Api.get(`news/${params}/comments`)
        commit('setNewsCommentList', res.data)
      }
      return res
    },

    async updateComments({ commit }, id, isHide) {
      await Api.post(`comment-news/${id}/update`, {
        is_hide: isHide,
      })
    },
  },
}
