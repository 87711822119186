import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class News extends Model {
  resource() {
    return 'news'
  }

  get publishDate() {
    return dateFormat(this.publish_date)
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get userName() {
    if (!this.user) return 'NA'
    return this.user.full_name
  }
}
