import PERMISSION from '@/utils/enums/Permission'

export default [
  {
    path: '/hub',
    name: 'hub',
    redirect: { name: 'hub.dashboard' },
    component: () => import(/* webpackChunkName: "hub" */ '@/views/Home/Hub'),
    children: [
      {
        path: 'dashboard',
        name: 'hub.dashboard',
        component: () =>
          import(
            /* webpackChunkName: "hub.dashboard" */ '@/views/Home/Hub/Dashboard'
          ),
      },
      {
        path: 'faqs',
        name: 'hub.faqs',
        meta: {
          requiresPermissions: ['VIEW_FAQS'],
        },
        component: () =>
          import(/* webpackChunkName: "hub.faqs" */ '@/views/Home/Hub/Faqs'),
      },
      {
        path: 'faqs/create',
        name: 'hub.faq.create',
        meta: {
          requiresPermissions: ['CREATE_FAQS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.faq.create" */ '@/views/Home/Hub/Faqs/CreateFaq'
          ),
      },
      {
        path: 'faqs/:id',
        name: 'hub.faq.details',
        meta: {
          requiresPermissions: ['VIEW_FAQS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.faq.details" */ '@/views/Home/Hub/Faqs/FaqDetails'
          ),
      },
      {
        path: 'contact-directory',
        name: 'hub.contact-directory',
        meta: {
          requiresPermissions: ['VIEW_CONTACTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.contact-directory" */ '@/views/Home/Hub/Contacts'
          ),
      },
      {
        path: 'contact-directory/create',
        name: 'hub.contact-directory.create',
        meta: {
          requiresPermissions: ['CREATE_CONTACTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.contact-directory.create" */ '@/views/Home/Hub/Contacts/CreateContact'
          ),
      },
      {
        path: 'contact-directory/:id',
        name: 'hub.contact-directory.details',
        meta: {
          requiresPermissions: ['VIEW_CONTACTS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.contact-directory.details" */ '@/views/Home/Hub/Contacts/ContactDetails'
          ),
      },
      {
        path: 'documents',
        name: 'hub.documents',
        component: () =>
          import(
            /* webpackChunkName: "hub.documents" */ '@/views/Home/Hub/Documents'
          ),
      },
      {
        path: 'documents/create',
        name: 'hub.documents.create',
        meta: {
          requiresPermissions: [PERMISSION.DOCUMENTS_CREATE],
        },
        component: () =>
          import(
            /* webpackChunkName: "hub.documents.create" */ '@/views/Home/Hub/Documents/CreateDocuments'
          ),
      },
      {
        path: 'documents/:id',
        name: 'hub.documents.details',
        component: () =>
          import(
            /* webpackChunkName: "hub.documents.details" */ '@/views/Home/Hub/Documents/DocumentDetails'
          ),
      },
    ],
  },
]
