const STATUS = [
  {
    text: 'Pending',
    value: 'pending',
    tier: 1,
  },
  {
    text: 'Declined',
    value: 'declined',
    tier: 2,
  },
  {
    text: 'Accepted',
    value: 'accepted',
    tier: 2,
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
    tier: 3,
  },
  {
    text: 'Done',
    value: 'done',
    tier: 3,
  },
]

export default STATUS
