export default [
  {
    path: '/maintenance',
    name: 'maintenance',
    redirect: { name: 'maintenance.dashboard' },
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '@/views/Home/Maintenance'),
    children: [
      {
        path: 'dashboard',
        name: 'maintenance.dashboard',
        meta: {
          requiresPermissions: ['VIEW_MAINTENANCES'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.dashboard" */ '@/views/Home/Maintenance/Requests'
          ),
      },
      {
        path: 'requests/:id',
        name: 'maintenance.request.details',
        meta: {
          requiresPermissions: ['VIEW_MAINTENANCES'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.request.details" */ '@/views/Home/Maintenance/Requests/RequestDetails'
          ),
      },
      {
        path: 'categories',
        name: 'maintenance.categories',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.categories" */ '@/views/Home/Maintenance/Categories'
          ),
      },
      {
        path: 'categories/create',
        name: 'maintenance.category.create',
        meta: {
          requiresPermissions: ['CREATE_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.category.create" */ '@/views/Home/Maintenance/Categories/CreateCategory'
          ),
      },
      {
        path: 'categories/:id',
        name: 'maintenance.category.details',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.category.details" */ '@/views/Home/Maintenance/Categories/CategoryDetails'
          ),
      },
      {
        path: 'urgencies',
        name: 'maintenance.urgencies',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgencies" */ '@/views/Home/Maintenance/Urgencies'
          ),
      },
      {
        path: 'urgencies/create',
        name: 'maintenance.urgency.create',
        meta: {
          requiresPermissions: ['CREATE_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgency.create" */ '@/views/Home/Maintenance/Urgencies/CreateUrgency'
          ),
      },
      {
        path: 'urgencies/:id',
        name: 'maintenance.urgency.details',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgency.details" */ '@/views/Home/Maintenance/Urgencies/UrgencyDetails'
          ),
      },
    ],
  },
]
