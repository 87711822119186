export default [
  {
    path: 'lobby-tvs',
    name: 'lobby-tvs',
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/Settings/LobbyTv/index'
      ),
  },
  {
    path: 'lobby-tvs/create',
    name: 'lobby-tvs.create',
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/Settings/LobbyTv/CreateLobbyTv'
      ),
  },
  {
    path: 'lobby-tvs/:id',
    name: 'lobby-tvs.details',
    component: () =>
      import(
        /* webpackChunkName: "settings.version-control" */ '@/views/Home/Settings/LobbyTv/LobbyTvDetail'
      ),
  },
]
