export default [
  {
    path: 'booking-requests',
    name: 'bookings',
    meta: {
      requiresPermissions: ['VIEW_BOOKINGS'],
    },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '@/views/Home/Bookings'),
  },
  {
    path: 'booking-requests/:id',
    name: 'booking.details',
    meta: {
      requiresPermissions: ['VIEW_BOOKINGS'],
    },
    component: () =>
      import(
        /* webpackChunkName: "booking.details" */ '@/views/Home/Bookings/BookingDetails'
      ),
  },
]
